.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-container {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px 15px;
    cursor: pointer;
    background: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 5px 5px 0 0;
  }
  
  .tab.active {
    background: #007bff;
    color: #fff;
    border-bottom: none;
  }
  
  .tab-content {
    border: 1px solid #ddd;
    border-radius: 0 0 5px 5px;
    padding: 20px;
  }
  
  .modal-table {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
  }
  
  .modal-actions button {
    margin-left: 10px;
  }
  